import { useContext, createContext } from 'react';
import { Module, User, ReferralBotInfo } from 'types';

export interface ContextProps {
  state: {
    module: Module | null;
    user: User | null;
    currentBot: ReferralBotInfo | null;
    title: string;
  };
  dispatch: (action: ReduceAction) => void;
}

export enum Action {
  SetModule = 'SetModule',
  SetUser = 'SetUser',
  SetCurrentBot = 'SetCurrentBot',
  SetTitle = 'SetTitle',
}

export type ReduceAction =
  | { type: Action.SetModule; payload: Module }
  | { type: Action.SetUser; payload: User }
  | { type: Action.SetCurrentBot; payload: ReferralBotInfo }
  | { type: Action.SetTitle; payload: string };

export const Context = createContext<ContextProps | undefined>(undefined);

export function reducer(state: ContextProps['state'], action: ReduceAction) {
  switch (action.type) {
    case Action.SetModule:
      return { ...state, module: action.payload };
    case Action.SetUser:
      return { ...state, user: action.payload };
    case Action.SetCurrentBot:
      return { ...state, currentBot: action.payload };
    case Action.SetTitle:
      return { ...state, title: action.payload };
    default:
      return state;
  }
}

export const initValue: ContextProps['state'] = {
  module: null,
  user: null,
  currentBot: null,
  title: '',
};

export const useSystemContext = () => {
  const ctx = useContext(Context);
  if (ctx === undefined) {
    throw new Error(
      "Missing context, 'useReferralSetting' must be called within the ReferralSettingContext",
    );
  }
  return ctx;
};

export const useCurrentBot = () => {
  const ctx = useContext(Context);
  if (ctx === undefined) {
    throw new Error(
      "Missing context, 'useReferralSetting' must be called within the ReferralSettingContext",
    );
  }
  return ctx.state.currentBot;
};

export const useUser = () => {
  const ctx = useContext(Context);
  if (ctx === undefined) {
    throw new Error(
      "Missing context, 'useReferralSetting' must be called within the ReferralSettingContext",
    );
  }
  return ctx.state.user;
};
