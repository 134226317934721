import { BUCKET_NAME } from 'AppConfig';
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
  authDomain: 'cresclab.firebaseapp.com',
  databaseURL: 'https://cresclab.firebaseio.com',
  projectId: 'cresclab',
  storageBucket: 'cresclab.appspot.com',
  messagingSenderId: '712000115132',
  appId: '1:712000115132:web:71e9529f9d963ac8a55bb9',
};

export const firebaseUploadImageToStorageConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_STORAGE_TOKEN,
  authDomain: 'line-market-place.firebaseapp.com',
  projectId: 'line-market-place',
  storageBucket: BUCKET_NAME,
  messagingSenderId: '1057103165244',
  appId: '1:1057103165244:web:415a0a82ee1a605a375039',
  measurementId: 'G-D6GXX9L4V5',
};
