import React from 'react';
// import { Trans } from 'react-i18next';
import styled from 'styled-components/macro';

import { Stepper, Steppers } from './Steper';

const Header = styled.div`
  height: 44px;
  padding-bottom: 22px;
  border-bottom: 1px solid #e6e8eb;
`;
const ContentWrapper = styled.div`
  height: calc(100% - 12px);
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-bottom: -32px;
  padding: 24px 32px 0px 32px;
  overflow-y: auto;
`;

interface SimpleStepperProps {
  steps: Array<{ title: string }>;
  current: number;
  onStepChange: (step: number) => void;
  headerSlot: React.ReactNode;
}

export const SimpleStepper: React.FC<SimpleStepperProps> = ({
  steps,
  current,
  onStepChange,
  headerSlot,
  children,
}) => {
  return (
    <>
      <Header>
        <Steppers numbered={true}>
          {steps.map((step, index) => (
            <Stepper
              key={`step${index}-${step.title}`}
              active={current === index}
              onClick={() => {
                onStepChange(index);
              }}
            >
              {/* <Trans>{step.title}</Trans> */}
              {step.title}
            </Stepper>
          ))}
        </Steppers>
        {headerSlot}
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};
