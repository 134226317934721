export const IS_ENV_STAGING = process.env.REACT_APP_API_ENDPOINT?.includes('staging') ?? false;

export const BUCKET_NAME = IS_ENV_STAGING ? 'prestissimo-dev' : 'prestissimo-dev';

export const BUCKET_FOLDER = `static/referral_upload_images`;

export const LINE_LOGIN_CHANNEL = IS_ENV_STAGING ? '1656323474' : '1656323437';

export const LINE_LOGIN_REDIRECT_URI = IS_ENV_STAGING
  ? `https://marketplace-staging.cresclab.com/login`
  : `https://marketplace.cresclab.com/login`;
