import { BoxProps, superBoxStyle } from 'components/layoutUtils';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components/macro';
import theme from 'theme';

import { cardVariants } from './variants';

interface CardWrapperProps extends BoxProps {
  isParallaxEffect?: boolean;
  isBlock?: boolean;
  style?: React.CSSProperties;
  onAnimationComplete?(): void;
}

export const AniCardsWrapper = styled(motion.div)`
  opacity: 0;
`;
const Card = styled(motion.div)<CardWrapperProps>`
  ${superBoxStyle};
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 3px 5px 0 rgba(18, 31, 48, 0.05);
  border-radius: 4px;
  vertical-align: top;
  overflow: hidden;
  border: 1px solid ${theme.colors.neutral005};
  /* pointer-events: ${({ isBlock }) => (isBlock ? 'none' : 'auto')}; */
  > div:not(.hint-box) {
    filter: ${({ isBlock }) => (isBlock ? 'blur(4px)' : '')};
  }
  ${({ isBlock }) =>
    isBlock
      ? `
  &:before{
    border-radius: 3px;
    content:'';
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background-color:white;
    z-index:10;
    opacity:0.8;
    pointer-events:auto;
  }
  `
      : null}
`;

export const CardWrapper: React.FC<CardWrapperProps> = ({ children, ...restProps }) => {
  return (
    <Card
      {...restProps}
      variants={cardVariants}
      onAnimationComplete={restProps.onAnimationComplete}
    >
      {children}
    </Card>
  );
};

CardWrapper.defaultProps = {
  display: 'inline-block',
};
