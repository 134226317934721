import axios from 'axios';

import isURLSameOrigin from '../utils/isURLSameOrigin';
import { GREEN, RED } from '../enum';
import { logger } from '../utils/loggerHelper';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const anonymousAxios = axios.create();

if (process.env.NODE_ENV === 'production') {
  anonymousAxios.interceptors.request.use((config) => {
    if (typeof config.url === 'string' && isURLSameOrigin(config.url)) {
      config.baseURL = process.env.REACT_APP_API_ENDPOINT;
    }
    return config;
  });
}

anonymousAxios.interceptors.response.use(
  (response) => {
    logger(response, GREEN);
    return response;
  },
  (error) => {
    logger(error.response, RED);
    return Promise.reject(error);
  },
);

export const AnonymousAxios = anonymousAxios;
