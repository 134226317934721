import { inDevelopment } from 'AppConstants';
import { AxiosError } from 'axios';

export const errorLogger = (error: AxiosError, color: string) => {
  const response = error.response;
  const request = error.request;

  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (inDevelopment) {
      console.groupCollapsed(
        `%c ${response.config.method} -> ${response.config.url}`,
        `color: ${color}`,
      );
      console.info('REQUEST:', {
        url: response.config.url,
        params: response.config.params,
        headers: response.config.headers,
      });
      console.info('RESPONSE:', {
        code: response.status,
        data: { ...response.data },
      });
      console.groupEnd();
    } else {
      console.error(response.data);
    }
  } else if (request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    if (inDevelopment) {
      console.error(request);
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error: ', error.message);
  }
};
