import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import theme from 'theme';

const bars = keyframes`
  0%    { transform: scale(1,1); }
  50%   { transform: scale(1,0.3); }
  100%  { transform: scale(1,1); }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  > div {
    flex: 1;
    margin-right: 4px;
    height: 100%;
    border-radius: 5px;
    animation-name: ${bars};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    &:last-child {
      margin: 0;
    }
  }
`;

const Bar1 = styled.div`
  background-color: ${theme.colors.blue006};
  animation-delay: 0ms;
`;
const Bar2 = styled.div`
  background-color: ${theme.colors.blue005};
  animation-delay: 200ms;
`;
const Bar3 = styled.div`
  background-color: ${theme.colors.blue004};
  animation-delay: 400ms;
`;
const Bar4 = styled.div`
  background-color: ${theme.colors.blue003};
  animation-delay: 600ms;
`;

interface LoadingProps {
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

export const Loading: React.FC<LoadingProps> = ({ style, wrapperStyle }) => (
  <div style={wrapperStyle}>
    <LoadingWrapper style={style}>
      <Spinner>
        <Bar1 />
        <Bar2 />
        <Bar3 />
        <Bar4 />
      </Spinner>
    </LoadingWrapper>
  </div>
);

const TableLoadingWrapper = styled.div`
  position: relative;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 50px;
`;

export const TableLoading: React.FC = () => (
  <TableLoadingWrapper>
    <Loading />
  </TableLoadingWrapper>
);
