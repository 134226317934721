import styled from 'styled-components/macro';

export const StepperHeader = styled.div`
  width: 100%;
  height: 44px;
  margin-bottom: 24px;
  padding-bottom: 22px;
  border-bottom: 1px solid #e6e8eb;
`;

export const Stepper = styled.div<{ active?: boolean }>`
  display: inline-block;
  position: relative;
  color: ${({ active }) => (active ? '#4586f0' : '#2c3848')};
  transition: 0.35s color ease-in-out;
  vertical-align: top;
  font-weight: 500;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 56px;
    &:after {
      content: '>';
      position: absolute;
      color: #2c3848;
      right: -33px;
      top: -2px;
    }
  }
`;

export const Steppers = styled.span<{ numbered?: boolean }>`
  display: inline-block;
  ${({ numbered }) =>
    numbered &&
    `counter-reset: my-awesome-counter;
      & ${Stepper} {
        counter-increment: my-awesome-counter;
        &::before {
          content: counter(my-awesome-counter) ". ";
        }
      }`}
`;
