import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AnonymousAxios as axiosUnAuth, isAxiosError } from 'lib/axios';
import { useQuery } from 'hooks/useQuery';

import {
  LOCAL_STORAGE_TOKEN,
  LOGIN_ERROR_NEED_REGISTER,
  FIREBASE_APP,
  OAUTH_LOADING,
} from 'AppConstants';

import firebase from 'firebase';
import { firebaseConfig, firebaseUploadImageToStorageConfig } from 'config/firebaseConfig';

export const useIsLogin = () => {
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();
  const isRegister = useQuery().get('state') === 'register';

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    firebase.initializeApp(firebaseUploadImageToStorageConfig, FIREBASE_APP.lineMarketPlace);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!Boolean(user)) {
        setIsLogin(false);
        if (window.location.pathname !== '/register' && window.location.pathname !== '/login')
          history.push('/login');
        //intiFireBaseUI();
      } else {
        const idToken = await user?.getIdToken();
        console.log({ user });
        if (idToken && user) {
          try {
            await switchToken(idToken);
          } catch (err) {
            if (err.message === LOGIN_ERROR_NEED_REGISTER) {
              window.sessionStorage.removeItem(OAUTH_LOADING);
              history.push('/login?need_register=true');
            }
            return;
          }
          setIsLogin(true);
          window.sessionStorage.removeItem(OAUTH_LOADING);
          if (window.location.pathname === '/register' || isRegister) {
            history.push('/welcome');
          } else if (window.location.pathname === '/login') {
            history.push('/');
          }
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return { isLogin, setIsLogin };
};

const switchToken = async (idToken: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isRegister = urlParams.get('state') === 'register';
  if (window.location.pathname === '/register' || isRegister) {
    const resp = await axiosUnAuth.post(`/users/v1/register/`, { id_token: idToken });
    localStorage.setItem(LOCAL_STORAGE_TOKEN, resp.data.token);
  } else {
    try {
      const resp = await axiosUnAuth.post(`/users/v1/login/`, { id_token: idToken });
      localStorage.setItem(LOCAL_STORAGE_TOKEN, resp.data.token);
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.error === LOGIN_ERROR_NEED_REGISTER) {
        throw new Error(LOGIN_ERROR_NEED_REGISTER);
      }
      throw new Error(error);
    }
  }
};
