import { AxiosError } from 'axios';

const isForbidden = (error: AxiosError) => {
  return error.response && error.response.status === 403;
};

export const isResourceGone = (error: AxiosError) => {
  return error.response && error.response.status === 410;
};

export const isAuthenticateFail = (err: AxiosError) => {
  return (
    isForbidden(err) &&
    err.response &&
    err.response.data.detail !== undefined &&
    err.response.data.detail === 'Authenticate fail'
  );
};

export const isPermissionDenied = (err: AxiosError) => {
  return (
    isForbidden(err) &&
    err.response &&
    err.response.data.detail !== undefined &&
    err.response.data.detail === 'You do not have permission to perform this action.'
  );
};
