import styled from 'styled-components/macro';
import { style, system } from 'styled-system';

import { BoxProps, superBoxStyle } from '../layoutUtils';
import { CSSPropertyCursor, CSSPropertyTextDecoration } from '../layoutUtils/type';

const overflow = style({
  prop: 'overflow',
});

type OverflowValue = 'auto' | 'hidden' | 'visible' | 'scroll';

type textTransform =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'full-width'
  | 'full-size-kana'
  | 'inherit'
  | 'initial'
  | 'unset';

export interface TextProps extends BoxProps, React.DOMAttributes<HTMLSpanElement> {
  value?: number;
  overflow?: OverflowValue;
  link?: boolean;
  required?: boolean;
  textTransform?: textTransform;
  textDecoration?: CSSPropertyTextDecoration;
  /** make the first letter capitalized or not */
  sentenceCase?: boolean;
  cursor?: CSSPropertyCursor;
}

export const Text = styled.span<TextProps>`
  ${system({
    textDecoration: true,
  })}
  ${system({
    textTransform: true,
  })}
  ${system({
    cursor: true,
  })}
  ${overflow};
  ${superBoxStyle};
  ${({ required }) =>
    required &&
    `
    &:before {
      content: '*';
      color: #CC585D;
      position: absolute;
      font-size:14px;
      right:-10px;
      top:-3px;
    }
  `}
  ${({ sentenceCase }) =>
    sentenceCase &&
    `
    display: inline-block;
    :first-letter {
      text-transform: capitalize;
    }
  `}
`;

Text.defaultProps = {
  fontSize: 14,
};
