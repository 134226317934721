import { Variants } from 'framer-motion';

export const cardVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 33, mass: 9, damping: 18 },
  },
  hidden: { opacity: 0, y: 20 },
};
export const cardsWrapperVariants: Variants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      delay: 0,
      when: 'beforeChildren',
    },
  },
  hidden: { opacity: 1 },
};
