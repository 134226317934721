// the code below is cope from 'axios/lib/helpers/isURLSameOrigin'
// cause type is not declared and ts-lint fuck me up.
// TODO: write a declare file, maybe.

export const isStandardBrowserEnv = (): boolean => {
  if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
    return false;
  }
  return typeof window !== 'undefined' && typeof document !== 'undefined';
};

const isURLSameOrigin = (requestURL: string) => {
  const msie = /(msie|trident)/i.test(navigator.userAgent);
  const urlParsingNode = document.createElement('a');
  let originURL;

  /**
   * Parse a URL to discover it's components
   *
   * @param {String} url The URL to be parsed
   * @returns {Object}
   */
  function resolveURL(url: string) {
    let href = url;

    if (msie) {
      // IE needs attribute set twice to normalize properties
      urlParsingNode.setAttribute('href', href);
      href = urlParsingNode.href;
    }

    urlParsingNode.setAttribute('href', href);

    // urlParsingNode provides the UrlUtils interface - http://url.spec.whatwg.org/#urlutils
    return {
      href: urlParsingNode.href,
      protocol: urlParsingNode.protocol ? urlParsingNode.protocol.replace(/:$/, '') : '',
      host: urlParsingNode.host,
      search: urlParsingNode.search ? urlParsingNode.search.replace(/^\?/, '') : '',
      hash: urlParsingNode.hash ? urlParsingNode.hash.replace(/^#/, '') : '',
      hostname: urlParsingNode.hostname,
      port: urlParsingNode.port,
      pathname:
        urlParsingNode.pathname.charAt(0) === '/'
          ? urlParsingNode.pathname
          : '/' + urlParsingNode.pathname,
    };
  }

  originURL = resolveURL(window.location.href);

  const parsed = typeof requestURL === 'string' ? resolveURL(requestURL) : requestURL;
  return parsed.protocol === originURL.protocol && parsed.host === originURL.host;
};

export default isURLSameOrigin;
