import { useEffect } from 'react';
import { AuthAxios as axios } from 'lib/axios';
import firebase from 'firebase';
import { Action, useSystemContext } from 'context';
import { Module, User } from 'types';
import { CURRENT_BOT_ID } from 'AppConstants';
import { getCookie } from 'utils/cookieUtils';

export const useUserInfo = (isLogin: boolean) => {
  const { dispatch } = useSystemContext();
  useEffect(() => {
    if (isLogin) {
      axios.get<User>(`/users/v1/user/${firebase.auth().currentUser?.uid}/`).then((resp) => {
        dispatch({ type: Action.SetUser, payload: resp.data });

        if (resp.data.official_accounts_map.referral.length > 0) {
          let index = 0;
          //拿到上一次使用者在SideBar設定的CurrentBot
          const currentBotId = getCookie(CURRENT_BOT_ID);
          if (currentBotId) {
            const currentBotIndex = resp.data.official_accounts_map.referral.findIndex(
              (d) => d.bot_id === currentBotId,
            );
            index = currentBotIndex >= 0 ? currentBotIndex : 0;
          }
          dispatch({
            type: Action.SetCurrentBot,
            payload: resp.data.official_accounts_map.referral[index],
          });
        }
      });
      axios.get<{ modules: Module[] }>(`/modules/v1/module/`).then((resp) => {
        dispatch({ type: Action.SetModule, payload: resp.data.modules[0] });
      });
    }
  }, [isLogin, dispatch]);
};
