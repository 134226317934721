import { colors as customColors } from './customColors';

const theme = {
  colors: {
    ...customColors,
  },
  device: {
    mobile: `(max-width: 520px)`,
    tablet: `(max-width: 768px)`,
    tabletWidth: 768,
  },
};

export type Theme = typeof theme;

export default theme;
