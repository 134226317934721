import { BoxProps, superBoxStyle } from 'components/layoutUtils';
import styled from 'styled-components/macro';

const StyledIconBox = styled.i<IconProps>`
  width: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  height: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  border-radius: 4px;
  padding: 2px 0;
  user-select: none;
  ${superBoxStyle};
`;

interface IconProps extends BoxProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  className?: string;
}

export const StyledIcon: React.FC<IconProps> = ({ children, ...rest }) => {
  return <StyledIconBox {...rest}>{children}</StyledIconBox>;
};
