import React from 'react';
import styled from 'styled-components/macro';
import {
  bottom,
  BottomProps,
  color as colorSystem,
  ColorProps,
  display,
  DisplayProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  height,
  HeightProps,
  left,
  LeftProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  position,
  PositionProps,
  right,
  RightProps,
  space,
  SpaceProps,
  style,
  textAlign as textAlignSystem,
  TextAlignProps,
  textStyle,
  TextStyleProps,
  top,
  TopProps,
  width,
  WidthProps,
} from 'styled-system';

interface TextEditorProps
  extends FontFamilyProps,
    FontSizeProps,
    SpaceProps,
    ColorProps,
    DisplayProps,
    LineHeightProps,
    TextAlignProps,
    TextStyleProps,
    LetterSpacingProps,
    PositionProps,
    TopProps,
    LeftProps,
    RightProps,
    BottomProps,
    WidthProps,
    HeightProps,
    React.DOMAttributes<HTMLInputElement> {
  // onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  // value?: string;
}

const overflow = style({
  prop: 'overflow',
});

export const TextEditorInput = styled.input<TextEditorProps>`
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${width}
  ${height}
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
  ${space};
  ${colorSystem};
  ${overflow};
  ${display};
  ${textAlignSystem};
  ${textStyle};
  ${lineHeight};
  ${letterSpacing};
  position: relative;
  ${position}
  border:1px solid transparent;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  transition: 0.25s border-color ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral004};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.neutral004};
  }
`;

export type TextAreaChangeEvent = React.ChangeEvent<HTMLTextAreaElement> &
  React.ChangeEvent<HTMLInputElement>;

export type TextAreaFocusEvent = React.FocusEvent<HTMLTextAreaElement> &
  React.FocusEvent<HTMLInputElement>;

export type TextAreaKeyboardEvent = React.KeyboardEvent<HTMLTextAreaElement> &
  React.KeyboardEvent<HTMLInputElement>;

export const TextEditorTextArea = styled.textarea<TextEditorProps>`
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${width}
  ${height}
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
  ${space};
  ${colorSystem};
  ${overflow};
  ${display};
  ${textAlignSystem};
  ${textStyle};
  ${lineHeight};
  ${letterSpacing};
  position: relative;
  ${position}
  border:1px solid ${({ theme }) => theme.colors.neutral004};
  border-radius: 4px;
  outline: none;
  /* background-color:transparent; */
  transition: 0.25s border-color ease-in-out;
  /* &:hover{
    border-color:${({ theme }) => theme.colors.neutral004};
  }
  &:focus{
    border-color:${({ theme }) => theme.colors.neutral004};
  } */
`;
