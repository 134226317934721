import React from 'react';
import { useIsLogin } from 'hooks/useIsLogin';
import { useUserInfo } from 'hooks/useUserInfo';
import { InitLoading } from 'components';

const AuthApp = React.lazy(() => import(/* webpackChunkName: "AuthApp" */ './AuthApp'));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "LoginPage" */ './Login/Login'));

export const AuthAndPublicApp = () => {
  const { isLogin } = useIsLogin();
  useUserInfo(isLogin);
  console.log({ isLogin });
  if (!isLogin) {
    return (
      <React.Suspense fallback={<InitLoading />}>
        <LoginPage />
      </React.Suspense>
    );
  }
  return (
    <React.Suspense fallback={<InitLoading />}>
      <AuthApp isLogin={isLogin} />
    </React.Suspense>
  );
};
