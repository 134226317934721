import { inDevelopment } from 'AppConstants';
import { AxiosResponse } from 'axios';

export const logger = (response: AxiosResponse, color: string) => {
  if (inDevelopment) {
    console.groupCollapsed(
      `%c ${response.config.method} -> ${response.config.url}`,
      `color: ${color}`,
    );
    console.info('REQUEST:', {
      url: response.config.url,
      params: response.config.params,
      headers: response.config.headers,
    });
    console.info('RESPONSE:', {
      code: response.status,
      data: { ...response.data },
    });
    console.groupEnd();
  }
};
