import { GENERAL_COOKIE_PREFIX } from 'AppConstants';
import Cookie, { CookieAttributes } from 'js-cookie';

/**
 * add general cookie name prefix
 * @param name cookie name
 * @param prefix general cookie name prefix
 * @returns cookieName
 */
export const cookieNameGenerator = (name: string, prefix?: boolean) => {
  const withPrefix = typeof prefix === 'boolean' ? prefix : true;
  const cookieName = withPrefix ? `${GENERAL_COOKIE_PREFIX}${name}` : name;

  return cookieName;
};

export const setCookie = (
  name: string,
  value: string,
  options?: {
    prefix?: boolean;
    expires?: CookieAttributes['expires'];
    ptah?: CookieAttributes['path'];
    domain?: CookieAttributes['domain'];
    secure?: CookieAttributes['secure'];
    sameSite?: CookieAttributes['sameSite'];
  },
) => {
  Cookie.set(cookieNameGenerator(name, options?.prefix), value, options);
};

export const getCookie = (name: string, prefix?: boolean) =>
  Cookie.get(cookieNameGenerator(name, prefix));
