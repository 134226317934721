import { Box, FramerBox } from 'components/layoutUtils/Box';
import styled from 'styled-components/macro';
import { flexbox, FlexboxProps } from 'styled-system';

export const Flex = styled(Box)<FlexboxProps>`
  display: flex;
  ${flexbox};
`;
export const FramerFlex = styled(FramerBox)<FlexboxProps>`
  display: flex;
  ${flexbox};
`;
