// key used in local storage
export const LOCAL_STORAGE_TOKEN = 'user';
export const LOCAL_STORAGE_JWT_PAYLOAD = 'jwtPayload';
export const LOCAL_STORAGE_LAST_CHANNEL = 'lastChannel';
export const LOCAL_STORAGE_LAST_CHANNEL_TYPE = 'lastChannelType';
export const LOCAL_STORAGE_LANGUAGE = 'language';
export const LOCAL_STORAGE_SIDEBAR_COLLAPSED = 'sidebar_collapsed';

// key name of cookies
export const GENERAL_COOKIE_PREFIX = 'mkp:';
export const CURRENT_BOT_ID = 'currentBot';

//oauth loading
export const OAUTH_LOADING = 'oauthLoading';
// value used in magic number
export const MEMBER_SCROLL_LIMIT = 100;
// 後端調整效能之後 member列表頁改為1000筆
export const MEMBER_LIST_LIMIT = 1000;
export const MAX_TAG_NAME_LENGTH = 30;
export const WEBSOCKET_RETRY_LIMIT = 30;
export const NOT_FOUND = -1;
/** 後端正在非同步計算數值 */
export const ASYNC_COUNT = -1;
export const COMPANY_FOUNDED_AT = '2017/05/17';
export const TESTER_LIMIT = 50;
export const MAAC_SCROLL_CONTENT_ID = 'maac-scroll-content';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'YYYY/MM/D';
export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;
export const TIME_FORMAT = 'HH:mm';
export const TIME_DISPLAY_FORMAT = ' h:mm A';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FORMAT_AM_PM = 'YYYY-MM-DD h:mm A';
export const DATE_TIME_FORMAT_AM_PM_SLASH = 'YYYY/M/D h:mm A';
export const SECOND_IN_MILLISECOND = 1000;
export const MINUTE_IN_MILLISECOND = SECOND_IN_MILLISECOND * 60;
export const HOUR_IN_MILLISECOND = MINUTE_IN_MILLISECOND * 60;
export const DAY_IN_MILLISECOND = HOUR_IN_MILLISECOND * 24;

// firebase app names
export const FIREBASE_APP = {
  default: undefined,
  lineMarketPlace: 'line-market-place',
};

export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'staging';

/** is NODE_ENV development */
export const inDevelopment = process.env.NODE_ENV === 'development';
/** is NODE_ENV production */
export const inProduction = process.env.NODE_ENV === 'production';
/** is NODE_ENV production */
export const inTest = process.env.NODE_ENV === 'test';

// Static file CDN
export const CDN_ORIGIN = 'https://cdn.maac.app';

// Trial user renew form link
export const TRIAL_EXPIRED_RENEW_FORM_LINK = 'https://www.cresclab.com/mkp-contact-page';

export function getBotId() {
  return parseInt(localStorage.getItem(LOCAL_STORAGE_LAST_CHANNEL)!, 0);
}

// #region MessageEventType
export const USER_TOKEN_EXPIRED = 'userTokenExpired';
export const RESOURCE_GONE = 'resourceGone';
// #endregion

export const GA_ID = 'UA-108450138-4';
export const GTM_ID = 'GTM-TF7VCG8';

/* spell-checker: disable */
export const MIME_TYPE = {
  csv: 'text/csv',
  excel_2003: 'application/vnd.ms-excel',
  excel_2007: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
/* spell-checker: enable */

export const DRAFT_JS_PARSE_TO_RAW_ERROR = 'Editor Draft Parse to RAW Error';

export const OPEN_COUNT_FEATURE_LAUNCH_DATE = '2021/03/29';

export const LOGIN_ERROR_NEED_REGISTER = 'need register user first';

export const LINE_MODULE_CHANNEL_SCOPE = `message:send message:receive account:manage message:mark_as_read profile:read`;
