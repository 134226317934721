import React from 'react';

/**
 * ```js
 * const text = useTextInput();
 * const value = text.value
 * text.clear()
 * return (
 *  <input {...text.bind} />
 * )
 * ```
 */
export function useTextInput(defaultValue: string = '') {
  const [value, setValue] = React.useState(defaultValue);

  const onChange = React.useCallback<(e: any) => void>((e) => setValue(e.target.value), []);

  const clear = () => setValue('');

  return {
    bind: {
      onChange,
      value,
    },
    value,
    clear,
    setValue,
  };
}
