import { mainColors } from './mainColors';
import { neutralColors } from './neutralColors';
import { subColors } from './subColors';

export const colors = {
  statusGrey: '#e6e6e6' as '#e6e6e6',
  statusYellow: '#ffe4aa' as '#ffe4aa',
  statusBlue: '#c9ecfb' as '#c9ecfb',
  statusPurple: '#edd6ff' as '#edd6ff',
  statusGreen: '#bfefd7' as '#bfefd7',
  statusRed: '#ffdddb' as '#ffdddb',
  grey001: '#404040' as '#404040',
  grey002: '#9b9b9b' as '#9b9b9b',
  grey003: '#e5e5e5' as '#e5e5e5',
  grey004: '#4a4a4a' as '#4a4a4a',
  grey005: '#a6a6a6' as '#a6a6a6',
  grey006: '#f5f5f5' as '#f5f5f5',
  grey007: '#b3b3b3' as '#b3b3b3',
  grey008: '#dbdbdb' as '#dbdbdb',
  grey009: '#808080' as '#808080',
  grey010: '#a9a9a9' as '#a9a9a9',
  grey011: '#d9d9d9' as '#d9d9d9',
  grey012: '#d7d7d7' as '#d7d7d7',
  grey013: '#cccccc' as '#cccccc',
  grey014: '#d8d8d8' as '#d8d8d8',
  mainBlue: '#353e54' as '#353e54',
  linkBlue: '#5496b2' as '#5496b2',
  white001: '#ffffff' as '#ffffff',
  dropdownHoverColor: 'rgba(216, 216, 216, 0.2)' as 'rgba(216, 216, 216, 0.2)',
  iconHoverGreen: '#11ab7e' as '#11ab7e',
  inputErrorRed: '#ff7c75' as '#ff7c75',
  mainGreen: '#63c89e' as '#63c89e',
  blud007: '#1f7fef' as '#1f7fef',

  darkBlue: '#2c3848' as '#2c3848',
  lightBlue: '#4586f0' as '#4586f0',
  ...mainColors,
  ...subColors,
  ...neutralColors,
};
