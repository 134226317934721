export const copyText = (text: string) => {
  return (
    window.navigator.permissions
      // @ts-ignore
      .query({ name: 'clipboard-write' })
      // @ts-ignore
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          return;
        } else {
          throw Error();
        }
      })
      .then(() => navigator.clipboard.writeText(text))
      .then(() => true)
      .catch(() => {
        var el = document.createElement('textarea');

        el.style.height = '0px';
        el.style.width = '0px';
        el.value = text;

        document.body.appendChild(el);

        el.select();
        document.execCommand('copy');
        el.remove();

        return true;
      })
      .catch(() => false)
  );
};
