import React from 'react';

import { Box, BoxProps } from '../layoutUtils';
import * as S from './Styled';

interface FieldsProps extends BoxProps {
  title?: React.ReactNode;
}

interface FieldsSecondaryTitleProps extends BoxProps {}
interface FieldsDescriptionProps extends BoxProps {}

export const Fields: React.FC<FieldsProps> = ({ children, title = '', ...styledBoxRest }) => {
  return (
    <Box mb="24px" width="338px" {...styledBoxRest}>
      <S.FieldTitleWrapper>{title}</S.FieldTitleWrapper>
      <S.FieldContentWrapper>{children}</S.FieldContentWrapper>
    </Box>
  );
};

export const MemoFields = React.memo(Fields);

export const FieldsSecondaryTitle: React.FC<FieldsSecondaryTitleProps> = ({
  children = '',
  ...styledBoxRest
}) => {
  return (
    <Box mb="8px" lineHeight="20px" color={'#2c3848'} {...styledBoxRest}>
      {children}
    </Box>
  );
};

export const FieldsDescription: React.FC<FieldsDescriptionProps> = ({
  children = '',
  ...styledBoxRest
}) => {
  return (
    <Box mb="8px" lineHeight="20px" color={'#858d99'} {...styledBoxRest}>
      {children}
    </Box>
  );
};

export const FieldsMultipleWrapper: React.FC<FieldsDescriptionProps> = ({
  children = '',
  ...styledBoxRest
}) => {
  return (
    <S.MultipleFieldsWrapper
      mb="8px"
      lineHeight="20px"
      display="flex"
      color={'#858d99'}
      {...styledBoxRest}
    >
      {children}
    </S.MultipleFieldsWrapper>
  );
};
