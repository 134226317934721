import { AuthAndPublicApp } from 'modules/AuthAndPublicApp';
import { BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';
import { Context, reducer, initValue } from 'context';
import { useReducer } from 'react';

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initValue);
  return (
    <BrowserRouter>
      <Switch>
        <Context.Provider value={{ state, dispatch }}>
          <AuthAndPublicApp />
        </Context.Provider>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
