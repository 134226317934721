import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { useTextInput } from 'hooks/useTextInput';
import React from 'react';

interface FileInputProps extends InputProps {}

export const FileInput: React.FC<FileInputProps> = ({ accept, onChange, ...rest }) => {
  const fileInput = useTextInput('');
  return (
    <Input
      {...rest}
      type="file"
      accept={accept}
      onChange={(e) => {
        onChange && onChange(e);
        fileInput.clear();
      }}
      value={fileInput.value}
    />
  );
};
