import logoSVGV2 from 'images/brand_logo_v2.svg';
import styled from 'styled-components/macro';

const LogoV2Wrapper = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
`;

const LogoV2 = () => {
  return <img src={logoSVGV2} alt="Logo" />;
};

export const InitLoading = () => {
  return (
    <LogoV2Wrapper>
      <LogoV2 />
    </LogoV2Wrapper>
  );
};
