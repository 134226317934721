import styled from 'styled-components/macro';

import { Box } from '../layoutUtils';

export const FieldTitleWrapper = styled.div`
  line-height: 20px;
  font-weight: 500;
  color: #2c3848;
  text-align: left;
  margin-bottom: 8px;
`;

export const FieldContentWrapper = styled.div``;

export const MultipleFieldsWrapper = styled(Box)`
  display: flex;
  > * {
    flex: 1 0 0;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;
