export const neutralColors = {
  neutral001: '#f7f8fa' as '#f7f8fa',
  neutral002: '#edeff2' as '#edeff2',
  neutral003: '#e6e8eb' as '#e6e8eb',
  neutral004: '#d5dae0' as '#d5dae0',
  neutral005: '#c2c6cc' as '#c2c6cc',
  neutral006: '#b1b5ba' as '#b1b5ba',
  neutral007: '#a1a8b3' as '#a1a8b3',
  neutral008: '#858d99' as '#858d99',
  neutral009: '#5c6066' as '#5c6066',
  neutral010: '#2c3848' as '#2c3848',
  neutral011: '#09172a' as '#09172a',
};
