import styled from 'styled-components/macro';
import theme from 'theme';

export const LoadingBar = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${theme.colors.neutral002};

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 6px;
    border-radius: 3px;
    background-color: ${theme.colors.blue006};
    animation: loading 2s linear infinite;
  }
  @keyframes loading {
    from {
      left: -200px;
      width: 30%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 70%;
    }
    80% {
      left: 50%;
    }
    95% {
      left: 120%;
    }
    to {
      left: 100%;
    }
  }
`;

export const FinishBar = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${theme.colors.green006};
`;
