export const subColors = {
  green001: '#e9f7eb' as '#e9f7eb',
  green002: '#c3ebcc' as '#c3ebcc',
  green003: '#95dea8' as '#95dea8',
  green004: '#6bd189' as '#6bd189',
  green005: '#45c46f' as '#45c46f',
  green006: '#23b759' as '#23b759',
  green007: '#149146' as '#149146',
  green008: '#0a6b34' as '#0a6b34',
  green009: '#034522' as '#034522',
  green010: '#011f10' as '#011f10',

  yellow001: '#fffceb' as '#fffceb',
  yellow002: '#fff3c2' as '#fff3c2',
  yellow003: '#ffe799' as '#ffe799',
  yellow004: '#ffd970' as '#ffd970',
  yellow005: '#ffc847' as '#ffc847',
  yellow006: '#ffb61f' as '#ffb61f',
  yellow007: '#d98f0f' as '#d98f0f',
  yellow008: '#b36d04' as '#b36d04',
  yellow009: '#8c4f00' as '#8c4f00',
  yellow010: '#663600' as '#663600',

  red001: '#fff9f7' as '#fff9f7',
  red002: '#fff3f0' as '#fff3f0',
  red003: '#ffcfc7' as '#ffcfc7',
  red004: '#ffa99e' as '#ffa99e',
  red005: '#fc7f74' as '#fc7f74',
  red006: '#f04f47' as '#f04f47',
  red007: '#d1324d' as '#d1324d',
  red008: '#ab203c' as '#ab203c',
  red009: '#85132d' as '#85132d',
  red010: '#5e0c22' as '#5e0c22',

  purple001: '#f9f0ff' as '#f9f0ff',
  purple002: '#f7edff' as '#f7edff',
  purple003: '#e3c4ff' as '#e3c4ff',
  purple004: '#cc9cff' as '#cc9cff',
  purple005: '#b273ff' as '#b273ff',
  purple006: '#9048f8' as '#9048f8',
  purple007: '#7852c4' as '#7852c4',
  purple008: '#583a9e' as '#583a9e',
  purple009: '#3c2678' as '#3c2678',
  purple010: '#261952' as '#261952',
};
