import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Text } from 'components';
import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { copyText } from 'utils/copyText';

interface StyledCopyButtonProps
  extends PositionProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    TypographyProps {
  color?: string;
  style?: React.CSSProperties;
}

const StyledWrap = styled('span')<StyledCopyButtonProps>`
  position: relative;
  color: #858d99;
  ${position}
  ${space}
  ${layout}
  ${color}
  ${typography}
`;

interface CopyButtonProps extends StyledCopyButtonProps {
  text: string;
  onCopied?(): void;
}

export const CopyButton: React.FC<CopyButtonProps> = memo(
  ({ text, onCopied, children, ...styleRest }) => {
    const [copied, setCopied] = useState(false);

    const successHandler = useCallback(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      onCopied?.();
    }, [onCopied]);

    const doCopy = useCallback(() => {
      copyText(text).then(() => {
        successHandler();
      });
    }, [successHandler, text]);

    return (
      <StyledWrap {...styleRest}>
        {copied ? (
          <Text textAlign="center">
            <CheckOutlined />
            {children && <Text ml="8px">{children}</Text>}
          </Text>
        ) : (
          <Text textAlign="center" onClick={doCopy} style={{ cursor: 'pointer' }}>
            <CopyOutlined />
            {children && <Text ml="8px">{children}</Text>}
          </Text>
        )}
      </StyledWrap>
    );
  },
);
