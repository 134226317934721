export const mainColors = {
  blue001: '#f0f8ff' as '#f0f8ff',
  blue002: '#edf6ff' as '#edf6ff',
  blue003: '#c4e1ff' as '#c4e1ff',
  blue004: '#9cc8ff' as '#9cc8ff',
  blue005: '#72abfc' as '#72abfc',
  blue006: '#4586f0' as '#4586f0',
  blue007: '#3066c9' as '#3066c9',
  blue008: '#1f49a3' as '#1f49a3',
  blue009: '#11307d' as '#11307d',
  blue010: '#0b1e57' as '#0b1e57',

  cyan001: '#e6fff3' as '#e6fff3',
  cyan002: '#d4fceb' as '#d4fceb',
  cyan003: '#a1f7d5' as '#a1f7d5',
  cyan004: '#73ebbf' as '#73ebbf',
  cyan005: '#45deab' as '#45deab',
  cyan006: '#1fd19c' as '#1fd19c',
  cyan007: '#11ab82' as '#11ab82',
  cyan008: '#078567' as '#078567',
  cyan009: '#005e4b' as '#005e4b',
  cyan010: '#00382f' as '#00382f',
};
