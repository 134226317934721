import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  OverflowProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  typography,
  TypographyProps,
} from 'styled-system';

import { CSSPropertyCursor } from './type';

export interface BoxProps
  extends FlexProps,
    PositionProps,
    SpaceProps,
    LayoutProps,
    TypographyProps,
    ColorProps,
    ShadowProps,
    BorderProps,
    BackgroundProps,
    OverflowProps {
  color?: string;
  cursor?: CSSPropertyCursor;
}

export const superBoxStyle = compose(
  background,
  border,
  space,
  shadow,
  position,
  typography,
  color,
  layout,
  flex,
  system({
    cursor: true,
  }),
);

export const Box = styled.div<BoxProps>`
  ${superBoxStyle}
`;

export const FramerBox = styled(motion.div)<BoxProps>`
  ${superBoxStyle}
`;

Box.defaultProps = {
  fontSize: '14px',
};
