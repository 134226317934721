import { RESOURCE_GONE, USER_TOKEN_EXPIRED, LOCAL_STORAGE_TOKEN } from 'AppConstants';
import axios, { AxiosError } from 'axios';
import { ResourceGoneEvent, UserTokenExpiredEvent } from 'types';
import { isAxiosError } from 'lib/axios';
import get from 'lodash/get';

import isURLSameOrigin from '../utils/isURLSameOrigin';
import { GREEN, RED } from '../enum';
import { errorLogger } from '../utils/errorLogger';
import { logger } from '../utils/loggerHelper';
import { isAuthenticateFail, isResourceGone } from '../utils/response';

const createLogoutMessage = (error: AxiosError): UserTokenExpiredEvent['data'] => {
  return {
    source: 'maac-web',
    payload: {
      event: USER_TOKEN_EXPIRED,
      payload: {
        request: {
          url: get(error, 'response.config.url', ''),
        },
      },
    },
  };
};

const createResourceGoneMessage = (error: AxiosError): ResourceGoneEvent['data'] => {
  return {
    source: 'maac-web',
    payload: {
      event: RESOURCE_GONE,
      payload: {},
    },
  };
};

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const authAxios = axios.create();

authAxios.interceptors.request.use((config) => {
  if (typeof config.url === 'string' && isURLSameOrigin(config.url)) {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    if (token && token.length) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
      if (process.env.NODE_ENV === 'production') {
        config.baseURL = process.env.REACT_APP_API_ENDPOINT;
      }
    }
  }
  return config;
});

authAxios.interceptors.response.use(
  (response) => {
    logger(response, GREEN);
    return response;
  },
  (error) => {
    errorLogger(error, RED);

    if (isAxiosError(error)) {
      if (isAuthenticateFail(error)) {
        window.postMessage(createLogoutMessage(error), window.location.origin);
      }

      if (isResourceGone(error)) {
        window.postMessage(createResourceGoneMessage(error), window.location.origin);
      }
    }

    return Promise.reject(error);
  },
);

export const AuthAxios = authAxios;
